import ButtonLoader from "../../components/loaders/ButtonLoader";
import ForgotPassword from "../../components/forgot-password/ForgotPassword";
import { useGlobalContext } from "../../context/context";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

const SignIn = () => {
  const {
    errorMessage,
    loginError,
    setForgotPassword,
    forgotPassword,
    loading,
    resetPage,
    setLoading,
    baseURL,
    setIsUser,
    setLoginError,
    setErrorMessage,
  } = useGlobalContext();
  useEffect(() => {
    resetPage(`Login`);
    // eslint-disable-next-line
  }, [forgotPassword]);

  const navigate = useNavigate();

  // FUNCTION TO LOGIN USER
  const login = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${baseURL}/auth/login`, {
        method: "POST",
        body: JSON.stringify(formik.values),
        headers: {
          "Content-type": "application/json",
        },
      });
      const data = await response.json();
      setLoading(false);

      if (response.status === 200) {
        setIsUser(true);
        localStorage.setItem(`userDetails`, JSON.stringify(data.user));
        localStorage.setItem(`userToken`, JSON.stringify(data.token));
        navigate(`/dashboard`);
      } else {
        setLoginError(true);
        setErrorMessage(data.message);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: ``,
      password: ``,
    },
    validationSchema: Yup.object({
      email: Yup.string().email(`Invalid Email`).required(`Email is required`),
      password: Yup.string()
        .min(8, `Minimum of 8 characters`)
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "At least 1 special character and a number"
        )
        .required("Password is required"),
    }),
    onSubmit() {
      login();
    },
  });

  if (forgotPassword) {
    return <ForgotPassword />;
  }

  return (
    <>
      <section className='sign-in'>
        <div className='form'>
          <form onSubmit={formik.handleSubmit}>
            {/* EMAIL INPUT */}
            <div className='form-control'>
              <label
                htmlFor='email'
                className={
                  formik.touched.email && formik.errors.email ? `red` : null
                }
              >
                {formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : `Email`}
              </label>
              <input
                type='email'
                placeholder='johnDoe@email.com'
                id='email'
                className='input'
                name='email'
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>

            {/* PASSWORD INPUT */}
            <div className='form-control'>
              <label
                htmlFor='password'
                className={
                  formik.touched.password && formik.errors.password
                    ? `red`
                    : null
                }
              >
                {formik.touched.password && formik.errors.password
                  ? formik.errors.password
                  : `Password`}
              </label>
              <input
                type='password'
                placeholder='**********'
                id='password'
                className='input'
                name='password'
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>

            {/* ERROR MESSAGE */}
            {loginError && (
              <div className={`error ${loginError && `show-error`}`}>
                <p>{errorMessage}</p>
              </div>
            )}

            {/* LOGIN BUTTON */}
            <div className='password-submitBtn'>
              <p onClick={() => setForgotPassword(true)}>Forgot password?</p>
              <div className='btn'>
                <button className='gold'>Sign in</button>
                {loading && <ButtonLoader />}
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default SignIn;
